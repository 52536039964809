import React, {Component,} from "react";
import "react-multi-email/style.css";

import {NavLink} from "react-router-dom";

import Table from "../../../../Components/UI/Table";
import PageHeader from "../../../../Components/UI/PageHeader";

import {
    Button,
    Card,
    Checkbox,
    Col,
    Descriptions,
    Form,
    Input,
    Modal,
    Radio,
    Row,
    Select,
    Space,
    Spin,
    Upload,
} from "antd";
import _ from "lodash";
import moment from "moment";
import QrCode from "../../../../Assets/qrcode.svg";

import {MenuOutlined, PlusOutlined, PrinterOutlined, UploadOutlined,} from "@ant-design/icons";
import Helper from "../../../../Helper";
import DataService from "../../../../Provider/Service/DataService";
import {sortableContainer, sortableElement, sortableHandle,} from "react-sortable-hoc";

import arrayMove from "array-move";

const {Option} = Select;
const {TextArea} = Input;

// img upload
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

const toBase64 = (url) =>
    fetch(url)
        .then((response) => response.blob())
        .then(
            (blob) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        resolve(reader.result);
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                })
        )
        .catch((err) => console.log("fetcherr", err));
const imageUrl = "https://apilims.intertekturkey.com/getImage/";
const DragHandle = sortableHandle(() => (
    <MenuOutlined style={{cursor: "pointer", color: "#999"}}/>
));

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

class SampleTestEdit extends Component {
    constructor(props) {
        super();
        this.state = {
            allChecked: true,
            loading: true,
            emails: ["test@mail.com", "test2@mail.com"],

            tableColumns: [
                {
                    title: "Sıralama",
                    dataIndex: "sort",
                    width: 30,
                    className: "drag-visible",
                    render: () => <DragHandle/>,
                },
                {
                    title: "Test Adı",
                    dataIndex: "name",
                    key: "name",
                    render: (text, record) => `${text}/${record?.name_en}`,
                },
                {
                    title: "Method",
                    dataIndex: "method",
                    key: "method",
                },
                {
                    title: "Birim",
                    dataIndex: "unit",
                    key: "unit",
                },
                {
                    title: "Akredite",
                    dataIndex: "accredited",
                    key: "accredited",
                    render: (text, record) => {
                        return text == 0 ? "Hayır" : "Evet";
                    },
                },
                {
                    title: "Ureal",
                    dataIndex: "ureal",
                    key: "ureal",
                },
                {
                    title: "Ref Min",
                    dataIndex: "ref_min",
                    key: "ref_min",
                    render: (text, record) => {
                        if (
                            record?.ref_min_operator != null &&
                            record?.ref_min_operator != "="
                        ) {
                            return `${record.ref_min_operator} ${text}`;
                        } else {
                            return text;
                        }
                    },
                },
                {
                    title: "Ref Max",
                    dataIndex: "ref_max",
                    key: "ref_max",
                    render: (text, record) => {
                        if (
                            record?.ref_max_operator != null &&
                            record?.ref_max_operator != "="
                        ) {
                            return `${record.ref_max_operator} ${text}`;
                        } else {
                            return text;
                        }
                    },
                },
                {
                    // tipe gore input veya select gelecek, select optionlarina numune secenekleri gelecek.
                    title: "Sonuç",
                    dataIndex: "result",
                    key: "result",
                    render: (text, record) => {
                        if (
                            record?.result_operator != null &&
                            record?.result_operator != "="
                        ) {
                            return `${record.result_operator} ${text}`;
                        } else {
                            return text;
                        }
                    },
                },
                {
                    title: "Ö.B",
                    dataIndex: "m_u",
                    key: "m_u",
                    render: (text, record) =>
                        record.number == 1
                            ? (parseFloat(record.ureal) * Number(record.result)).toFixed(2)
                            : "N/A",
                },
                {
                    title: "Analizi Yapan",
                    dataIndex: "testUserId",
                    key: "testUserId",
                    render: (text, record) => {
                        return (
                            <Select
                                showSearch
                                style={{width: 150}}
                                placeholder="Kullanıcı Seçiniz"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                    0
                                }
                                disabled={true}
                                defaultValue={text}
                            >
                                {this.state?.sampleUsers?.map((item, index) => {
                                    return (
                                        <Option
                                            value={item.userId}
                                        >{`${item.name}  ${item.lastname}`}</Option>
                                    );
                                })}
                            </Select>
                        );
                    },
                },
                {
                    title: "Açıklamalar",
                    dataIndex: "report_desc_ids",
                    key: "report_desc_ids",
                    render: (text, record, rowIndex) => {
                        return (
                            <Select
                                showSearch
                                mode="multiple"
                                style={{width: 150}}
                                placeholder="Seçiniz"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                    0
                                }
                                defaultValue={text}
                                onChange={(value) => this.acredite_changed(value, record, rowIndex)}
                                onDeselect={(value) => this.acredite_deleted(value, record, rowIndex)}
                            >
                                {this.state?.accredited_options?.map((item, index) => {
                                    return (
                                        <Option key={index}
                                                value={item.report_desc_id}
                                        >{`${item.name} - ${item.name_en}`}</Option>
                                    );
                                })}
                            </Select>
                        );
                    },
                },
                {
                    title: "Raporlanacak",
                    //title: <Button onClick={() => this.selectAll()}> Hepsini Seç</Button>,
                    key: "reported",

                    render: (text, record, rowIndex) => {
                        return (
                            <Checkbox
                                id={`${rowIndex}-checkbox`}
                                defaultChecked={record?.reported || true}
                                checked={record?.reported}
                                onChange={() => this.reportedChanged(record)}
                            />
                        );
                    },
                },
            ],
            data: [],
            descData: [],
            typeData: [],
            details: {
                reportNo: "N/A",
                sectorName: "N/A",
                sectorNameEn: "N/A",
                getLocation: "N/A",
                getPartAdd: "N/A",
                getMethod: "N/A",
                productName: "N/A",
                productNameEn: "N/A",
                getAcceptanceDate: "N/A",

                getAcceptanceHour: "",
                statusId: "N/A",
                operationName: "N/A",

                customer_name: "N/A",
                dateDelivery: "N/A",
                getUser: "N/A",
                amount: "N/A",
                amount_type: "N/A",
                signetCode: "N/A",
                getSenderName: "N/A",
            },
            users: [{userId: 0, fullName: "N/A"}],
            sampleUsers: [{userId: 0, fullName: "N/A"}],
            sampleId: "",
            complated: 0,
            count: 0,
            progressBar: 0,
            checked: [],
            reported: false,
            description: "",
            modalRevizeVisible: false,
            modalReportVisible: false,
            modalRevizeEditVisibled: false,
            modalAcredite: false,
            acrediteNameTr: null,
            acrediteNameEn: "",
            acrediteDescTr: null,
            acrediteDescEn: "",
            optionArray: [],
            modalType: "revised",
            btnLoading: false,
            referanceStandartData: [],
            info: "",
            fileList: [],
            reportInfo: {
                reportTypeId: undefined,
                reportDesc: "",
                reportDescEn: "",
                standadId: undefined,
                signUserId: undefined,
                signCUserId: undefined,
                sampleCommentRevised: undefined,
                sampleCommentRevisedEn: undefined,
                sendmailreport: undefined,
            },
            reportSignatureType: 0,
            reportRevisedType: false,
            reportEMailListType: false,
        };
    }

    formRef = React.createRef();

    componentDidMount() {
        //console.log('reportInfo', this.state?.reportInfo);
        this.getData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.allChecked !== this.state.allChecked) {
            this.allCheckedEvent()
        }

    }

    allCheckedEvent() {
        let newData = [];
        this.state.data.map((v, i) => {
            newData.push({
                ...v,
                reported: this.state.allChecked
            })
        })
        //console.log('newData', newData)
        this.setState({data: newData})
    }

    getData = async () => {
        try {
            //console.log("SampleID", this.props.match?.params?.sampleId);
            if (this.props.match?.params?.sampleId) {
                const result = await DataService.Sample.getTests(
                    this.props.match?.params?.sampleId
                );

                //console.log("Sample Tests", result)

                const resultDesc = await DataService.Sample.showReportDesc();
                const resultType = await DataService.Sample.showReportType();
                const resultStandart = await DataService.ReferanceStandart.SelectAll();
                let data = [],
                    reported = false;
                if (
                    result.status &&
                    resultDesc.status &&
                    resultType.status &&
                    resultStandart.status
                ) {
                    let complated = 0,
                        count = result.data.length;
                    for (const key in result.data) {
                        if (result.data.hasOwnProperty(key)) {
                            const element = result.data[key];
                            if (element?.reported == undefined) {
                                element.reported = true;
                            }
                            data.push({
                                ...element,
                                key,
                                index: data.length,
                            });
                            if (element.result != null) {
                                complated += 1;
                            }
                        }
                    }
                    const detailsData = {
                        ...result.details,
                        getAcceptanceHour: result?.details?.getAcceptanceHour
                            ? moment(Number(result.details.getAcceptanceHour) * 1000).format(
                                "hh:mm"
                            )
                            : "",
                    };
                    let fileList = [];
                    for (const key in result.photo) {
                        if (result.photo.hasOwnProperty(key)) {
                            const element = result.photo[key];
                            fileList.push({
                                uid: key,
                                name: element.photo,
                                status: "done",
                                url: `${imageUrl}${element.photo}`,
                            });
                        }
                    }

                    //console.log("Report type?", resultType.data)

                    this.setState({
                        data: data,
                        details: detailsData,
                        descData: resultDesc.data,
                        typeData: resultType.data,
                        referanceStandartData: resultStandart?.response?.data || [],
                        users: [...this.state.users, ...result.users],
                        sampleUsers: [...this.state.sampleUsers, ...result.sampleUsers],
                        accredited_options: result.acredite,
                        fileList,
                        loading: false,
                        sampleId: this.props.match?.params?.sampleId,
                        complated,
                        count,
                        info: result.info,
                        reportInfo: result.reportInfo,
                        reportSignatureType: result?.reportInfo?.signatureType || 0,
                        reportEMailListType: Boolean(
                            result?.reportInfo?.sendmailreport || 0
                        ),
                    });
                    if (result.reportInfo.sampleRevised > 0) {
                        this.setState({
                            reportRevisedType: true,
                        });
                    }

                    if (this.state.details.sectorName.includes("LUBOIL")) {
                        this.setState({
                            typeData: resultType.data.filter(item => item.type.includes('ocm'))
                        });
                    } else {
                        this.setState({
                            typeData: resultType.data.filter(item => !item.type.includes('ocm'))
                        });
                    }

                } else {
                    !result.status && Helper.ErrorMessage(result.message);
                    !resultType.status && Helper.ErrorMessage(resultType.message);
                    !resultDesc.status && Helper.ErrorMessage(resultDesc.message);
                    !resultStandart.status && Helper.ErrorMessage(resultStandart.message);
                    this.props.history.push("/sample/sample-report");
                }
            } else {
                Helper.ErrorMessage("Rapor Bulunamadı");
                this.props.history.push("/sample/sample-report");
            }
        } catch (error) {
            Helper.ErrorMessage(error.toString());
            this.props.history.push("/sample/sample-report");
        }
    };
    acredite_changed = (value, record, rowIndex) => {
        //console.log(record, rowIndex)
        let optionArray = [];
        for (const key in value) {
            if (value.hasOwnProperty(key)) {
                const element = value[key];

                const find = _.find(this.state.accredited_options, {
                    report_desc_id: element,
                });

                optionArray.push(find);
            }
        }
        const newData = [...this.state.data];
        const newOptions = [...this.state.optionArray];
        const index = newData.findIndex((item) => record.test_id === item.test_id);
        // const item = newData[index];

        // Get rowIndex instead of findIndex method to fix the error causes when "tank by tank"
        const item = newData[rowIndex];

        const report_desc_ids = [];
        for (const key in optionArray) {
            if (optionArray.hasOwnProperty(key)) {
                const element = optionArray[key];
                report_desc_ids.push(element.report_desc_id);
                const finds = _.find(this.state.optionArray, {
                    report_desc_id: element.report_desc_id,
                });
                if (!finds) {
                    newOptions.push(element);
                }
            }
        }
        item.report_desc_ids = report_desc_ids;
        // newData.splice(index, 1, {...item});

        // Get rowIndex instead of findIndex method to fix the error causes when "tank by tank"
        newData.splice(rowIndex, 1, {...item});

        this.setState({
            data: newData,
            optionArray: newOptions,
        });
    };
    acredite_deleted = (value, record, rowIndex) => {
        const newOptions = [...this.state.optionArray];
        for (const key in newOptions) {
            if (newOptions.hasOwnProperty(key)) {
                const element = newOptions[key];
                if (value === element?.report_desc_id) {
                    const find = _.find(this.state.data, (items) => {
                        const acrediteFind = _.find(
                            items.report_desc_ids,
                            (finds) => finds == value
                        );

                        return acrediteFind;
                    });
                    if (!find) {
                        const index = newOptions.findIndex(
                            (item) => item?.report_desc_id === value
                        );

                        newOptions.splice(index, 1);
                    }
                }
            }
        }

        this.setState({
            optionArray: newOptions,
        });
    };
    acredite_save = () => {
        const {acrediteNameTr, acrediteNameEn, acrediteDescTr, acrediteDescEn} =
            this.state;
        if (!acrediteNameTr) {
            Helper.warningMessage("Başlık girilmesi zorunludur.");
            return;
        }

        if (!acrediteDescTr) {
            Helper.warningMessage("Türkçe açıklama girilmesi zorunludur.");
            return;
        }

        const addItem = {
            name: acrediteNameTr,
            name_en: acrediteNameEn,
            desc: acrediteDescTr,
            desc_en: acrediteDescEn,
        };

        DataService.Sample.reportDescCreates(addItem)
            .then((result) => {
                if (!result.status) {
                    Helper.ErrorMessage(result.message);
                    return;
                }
                Helper.SuccessMessage("Başarılı bir şekilde eklendi");
                this.setState(
                    {
                        acrediteNameTr: "",
                        acrediteNameEn: "",
                        acrediteDescTr: "",
                        acrediteDescEn: "",

                        modalAcredite: false,
                    },
                    this.getData
                );
            })
            .catch((error) => Helper.ErrorMessage(error.toString()));
    };
    _getSampleTests = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const {data} = this.state;
                let sampleData = [];
                for (const key in data) {
                    if (data.hasOwnProperty(key)) {
                        const element = data[key];

                        const new_element_reported = element?.reported == true ? 1 : 0;
                        // console.log("element?.reported mg", element?.reported);
                        // console.log("new_element_reported", new_element_reported);

                        sampleData.push({
                            test_id: element.test_id,
                            options: element.report_desc_ids,
                            sampleTestId: element.sampleTestId,
                            reported: new_element_reported,
                        });
                    }
                }
                resolve(sampleData);
            } catch (error) {
                reject(error);
            }
        });
    };
    sendReport = async ({
                            reportDesc,
                            reportDescEn,
                            reportDescs,
                            reportTypeId,
                            reportStandartId,
                            reportSignatureUserId,
                            reportCuSignatureUserId,
                            reportSignatureType,
                            light,
                            sampleCommentRevised,
                            sampleCommentRevisedEn,
                            reportEMailListType,
                        }) => {
        this.setState({btnLoading: true});
        try {
            const sampleData = await this._getSampleTests();

            const {sampleId, fileList} = this.state;
            /*
            let descriptions = [];
            for (const key in reportDescs) {
              if (reportDescs.hasOwnProperty(key)) {
                const element = reportDescs[key];
                descriptions.push({
                  report_desc_id: element,
                });
              }
            }
            */
            let images = [];
            for (const key in fileList) {
                if (fileList.hasOwnProperty(key)) {
                    const element = fileList[key];

                    try {
                        let image;
                        if (element.url) {
                            image = await toBase64(element.url);
                            image = new Blob([image], {type: "image/jpeg"});
                        } else if (element.originFileObj) {
                            image = await getBase64(element.originFileObj);
                            image = new Blob([image], {type: element.originFileObj.type});
                        } else {
                            image = new Blob([element.url], {type: "image/jpeg"});
                        }

                        images.push({
                            image,
                            name: element.name,
                        });
                    } catch (error) {
                        console.log("fileList-keys", error);
                    }
                }
            }

            if (sampleId) {
                /*
                console.log("formSender", {
                    sampleId,
                    description: reportDesc,
                    description_en: reportDescEn,
                    report_type_id: reportTypeId,
                    descriptions: sampleData,
                    standart_id: reportStandartId,
                    images,
                    reportSignatureUserId,
                    reportCuSignatureUserId,
                    reportSignatureType: Number(reportSignatureType),
                    sampleCommentRevised,
                    sampleCommentRevisedEn,
                    sendmailreport: reportEMailListType,
                });
                 */

                const result = await DataService.Sample.sendReport({
                    sampleId,
                    description: reportDesc,
                    description_en: reportDescEn,
                    report_type_id: reportTypeId,
                    descriptions: sampleData,
                    standart_id: reportStandartId,
                    images,
                    reportSignatureUserId,
                    reportCuSignatureUserId,
                    reportSignatureType: Number(reportSignatureType),
                    light,
                    sampleCommentRevised,
                    sampleCommentRevisedEn,
                    sendmailreport: reportEMailListType,
                });

                if (result.status) {
                    this.setState({
                        btnLoading: false,
                        modalReportVisible: false,
                    });

                    Helper.SuccessMessage(result.fileName);
                    this.props.history.push("/sample/sample-report");
                } else {
                    this.setState({btnLoading: false});
                    Helper.ErrorMessage(result.message);
                    this.props.history.push("/sample/sample-report");
                }
            } else {
                this.setState({btnLoading: false});
                Helper.warningMessage("Numune Bulunamadı");
                this.props.history.push("/sample/sample-report");
            }
        } catch (error) {
            this.setState({btnLoading: false});
            Helper.ErrorMessage(error.toString());
            this.props.history.push("/sample/sample-report");
        }
    };
    sendRevised = async (type = 1) => {
        this.setState({btnLoading: true});
        try {
            const {sampleId, description, checked} = this.state;
            if (description == "") {
                const message =
                    type == 1
                        ? "Lütfen revize açıklamasını giriniz."
                        : "Lütfen  değişiklik açıklamasını giriniz.";
                Helper.warningMessage(message);
                this.setState({btnLoading: false});
                return;
            }
            if (sampleId) {
                let sampleTestIds = [];
                for (const key in checked) {
                    if (checked.hasOwnProperty(key)) {
                        const element = checked[key];
                        sampleTestIds.push(element.test_id);
                    }
                }

                const result = await DataService.Sample.sendRevised({
                    sampleId,
                    description,
                    sampleTestIds,
                    type,
                });
                this.setState({btnLoading: false, description: ""});
                if (result.status) {
                    this.setState({
                        btnLoading: false,
                        modalRevizeVisible: false,
                    });
                    Helper.SuccessMessage(result.message);
                    this.props.history.goBack();
                } else {
                    this.setState({btnLoading: false});
                    Helper.ErrorMessage(result.message);
                    this.props.history.goBack();
                }
            } else {
                this.setState({btnLoading: false, description: ""});

                if (!sampleId) {
                    Helper.ErrorMessage("Numune bulunamadı.");
                    this.props.history.goBack();
                }
            }
        } catch (error) {
            this.setState({btnLoading: false, description: ""});
            Helper.ErrorMessage(error.toString());
            this.props.history.goBack();
        }
    };
    rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({
                checked: selectedRows,
            });
        },
        getCheckboxProps: (record) => {
            return {
                disabled: this.state.details.statusId == 11, // Column configuration not to be checked
                name: `checkbox-${record.key}`,
            };
        },
        columnTitle: "Revize",
    };

    selectAll = () => {
        console.log('sdfas', this.state.newArray);
    }
    tableFooter = () => {
        return this.state.optionArray.map((item, index) => {
            return (
                <p>
                    {Array(index + 1)
                        .fill("*")
                        .toString()
                        .replace(/,/g, "")}{" "}
                    <b>{item?.name}:</b> {item?.desc}
                </p>
            );
        });
    };
    // img upload
    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle:
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
        });
    };
    handleChange = ({fileList}) => {
        this.setState({fileList});
    };

    handleCancel = () => this.setState({previewVisible: false});
    reportedChanged = (record, checkAll = null) => {
        if (!checkAll) {
            let newArray = [...this.state.data];
            newArray = _.reject(newArray, {test_id: record.test_id});
            record.reported = !record.reported;
            newArray.push(record);
            this.setState({newArray});
        }
        if (checkAll === 'selectAll') {
            console.log('newArray', this.state.newArray);
        }

    };
    DraggableBodyRow = ({className, style, ...restProps}) => {
        const {data} = this.state;
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = data.findIndex((x) => x.index === restProps["data-row-key"]);
        return <SortableItem index={index} {...restProps} />;
    };
    onSortEnd = ({oldIndex, newIndex}) => {
        const {data} = this.state;

        if (oldIndex !== newIndex) {
            const newData = arrayMove([].concat(data), oldIndex, newIndex).filter(
                (el) => !!el
            );

            this.setState({data: newData});
        }
    };


    render() {
        // console.log("tet",this.state.reportInfo)
        const {emails} = this.state;

        const {details, data, tableColumns, referanceStandartData, users} =
            this.state;
        const referansceStandartOptions = referanceStandartData.map(
            (item, index) => {
                return (
                    <Option key={index} value={item.standard_id}>
                        {item.name}
                    </Option>
                );
            }
        );
        const userSelect = users.map((item, index) => {
            return (
                <Option key={index} value={item.userId}>
                    {item.fullName}
                </Option>
            );
        });
        const uploadButton = (
            <div>
                <UploadOutlined/>
                <div className="ant-upload-text">Yükle</div>
            </div>
        );
        const DraggableContainer = (props) => (
            <SortableContainer
                useDragHandle
                helperClass="row-dragging"
                onSortEnd={this.onSortEnd}
                {...props}
            />
        );
        return (
            <div>
                <div className="pageHeaderWrapper">
                    <PageHeader title={`Rapor Oluştur : ${details.reportNo}`}/>

                    <div className="pageHeaderOptions">
                        <Space>
                            <NavLink
                                activeClassName="nav-active"
                                exact
                                to="/sample/sample-report"
                            >
                                <Button className="btn-list" shape="round">
                                    Numune Rapor Listesi
                                </Button>
                            </NavLink>
                        </Space>
                    </div>
                </div>

                <Spin spinning={this.state.loading}>
                    <Card bordered={false} className="cardTable">
                        <div
                            className="sample-information"
                            style={{marginBottom: "24px"}}
                        >
                            <h2>Numune Bilgileri</h2>
                            <Row gutter="16" style={{marginBottom: "16px"}}>
                                <Col span={24} md={21}>
                                    <Descriptions className="descriptionList" title="" bordered>
                                        <Descriptions.Item label="Operasyon">
                                            {details.operationName}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="İş Kolu">
                                            {details.sectorName}
                                        </Descriptions.Item>

                                        <Descriptions.Item label="Müşteri">
                                            {details.customer_name}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Terminal">
                                            {details.getLocation}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Numune Alma Tarihi">
                                            {details.dateDelivery}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Numuneyi Alan">
                                            {`${details.getUser} / ${details.getUserCustomer}`}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Numune Miktarı">
                                            {`${details.amount}${details.amount_type}`}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Numune Kabul Tarih">
                                            {`${details.getAcceptanceDate} ${details.getAcceptanceHour}`}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Numune Adı ve Tarifi">
                                            {`${details.productName} / ${details.productNameEn}`}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Alındığı Yer">
                                            {details.getPart}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Mühür Bilgileri">
                                            {details.signetCode}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Numuneyi Teslim Eden">
                                            {`${details.getSenderName} / ${details.getSenderCustomer}`}
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                            label={this.state.details.sectorName.includes("LUBOIL (OCM)") ? "Yağ Marka" : "Numune Alma Metodu"}>
                                            {details.getMethod}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Deney Tarihi">
                                            {details.dateFinish}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                                <Col span={24} md={3}>
                                    <img src={QrCode} width="" style={{width: "100%"}}/>
                                </Col>
                            </Row>
                        </div>

                        <div style={{width: '100%', marginBottom: '10px', textAlign: 'end'}}>
                            <Button onClick={() => this.setState({allChecked: !this.state.allChecked})}>
                                Hepsini Seç
                            </Button>
                        </div>


                        <Table
                            columns={tableColumns}
                            data={data}
                            pagination={false}
                            rowKey={"index"}
                            rowSelection={{
                                type: "checkbox",

                                ...this.rowSelection,
                            }}
                            footer={() => this.tableFooter()}
                            components={{
                                body: {
                                    wrapper: DraggableContainer,
                                    row: this.DraggableBodyRow,
                                },
                            }}
                        />

                        <div className="formButton" style={{marginBottom: "26px"}}>
                            <Space size={15}>
                                <Button
                                    type="primary"
                                    onClick={() => this.setState({modalAcredite: true})}
                                >
                                    <PrinterOutlined/> Açıklama Ekle
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={() =>
                                        this.setState({modalRevizeVisible: "revised"})
                                    }
                                    disabled={this.state.details.statusId == 11}
                                >
                                    <PrinterOutlined/> Revizeye Gönder
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={() =>
                                        this.setState({modalRevizeEditVisibled: true})
                                    }
                                    disabled={this.state.details.statusId == 11}
                                >
                                    <PrinterOutlined/> Raporda Değişiklik Yap
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={() =>
                                        this.setState({modalReportVisible: "report"}, () => {
                                            let reportStandartId = null;
                                            if (this.state?.reportInfo?.standadId) {
                                                reportStandartId = this.state?.reportInfo?.standadId;
                                            } else {
                                                const find = _.find(this.state.referanceStandartData, {
                                                    product_id: this.state.details?.productId,
                                                });
                                                reportStandartId = find?.standard_id || null;
                                            }

                                            setTimeout(() => {
                                                this.formRef.current.setFieldsValue({
                                                    reportStandartId,
                                                });
                                            }, 100);
                                        })
                                    }
                                    disabled={this.state.details.statusId == 11}
                                >
                                    <PlusOutlined/>
                                    Raporu Oluştur
                                </Button>
                            </Space>
                        </div>
                    </Card>
                </Spin>
                <Modal
                    title={"Rapor Oluştur"}
                    visible={this.state.modalReportVisible}
                    onCancel={() =>
                        this.setState(
                            {
                                modalReportVisible: false,
                                btnLoading: false,
                                reportEMailListType: false,
                                emails: [],
                                reportSignatureType: 0,
                            },
                            () => this.formRef.current.resetFields()
                        )
                    }
                    footer=""
                >
                    {this.state.details.statusId == 7 && (
                        <p>
                            Raporu düzenlemek üzeresiniz. Kaydettiğinizde daha önce
                            oluşturulan raporunuz silinecektir.
                        </p>
                    )}
                    <Form ref={this.formRef} onFinish={this.sendReport}>
                        <Form.Item
                            name="reportTypeId"
                            label="Raporlama Tipi"
                            rules={[
                                {required: true, message: "Lütfen raporlama tipini seçiniz"},
                            ]}
                            initialValue={this.state?.reportInfo?.reportTypeId}
                        >
                            <Select placeholder="Seçiniz" optionFilterProp="children">
                                {this.state.typeData?.map((item, index) => {
                                    return (
                                        <Option value={item.report_type_id}>
                                            {item.name}/{item.name_en}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="reportStandartId"
                            label="Referans Standardı"
                            rules={[
                                {required: true, message: "Lütfen raporlama tipini seçiniz"},
                            ]}
                            initialValue={this.state?.reportInfo?.standadId || 0}
                        >
                            <Select showSearch placeholder="Seçiniz" optionFilterProp="children">
                                {referansceStandartOptions}
                            </Select>
                        </Form.Item>

                        {this.state.details.sectorName.includes("LUBOIL (OCM)") &&
                            <Form.Item
                                name="light"
                                label="Işık"
                                initialValue={this.state?.reportInfo?.light}
                            >
                                <Select
                                    showSearch
                                    placeholder="Seçiniz"
                                    optionFilterProp="children"
                                >
                                    <Option key={0} value={'-'}>
                                        -
                                    </Option>
                                    <Option key={1} value={'green'}>
                                        Green
                                    </Option>
                                    <Option key={2} value={'yellow'}>
                                        Yellow
                                    </Option>
                                    <Option key={3} value={'red'}>
                                        Red
                                    </Option>
                                </Select>
                            </Form.Item>
                        }

                        <Form.Item
                            name="reportDesc"
                            label="Rapor Açıklama TR"
                            rules={[
                                {
                                    required: false,
                                    message: "Lütfen rapor açıklamasını giriniz.",
                                },
                            ]}
                            initialValue={
                                this.state?.reportInfo?.reportDesc || this.state?.info
                            }
                        >
                            <TextArea rows={4} placeholder="Açıklama giriniz."/>
                        </Form.Item>

                        <Form.Item
                            name="reportDescEn"
                            label="Rapor Açıklama EN"
                            rules={[
                                {
                                    required: false,
                                    message: "Lütfen rapor açıklamasını giriniz.",
                                },
                            ]}
                            initialValue={
                                this.state?.reportInfo?.reportDesc_en
                            }
                        >
                            <TextArea rows={4} placeholder="Açıklama giriniz."/>
                        </Form.Item>
                        {this.state.reportRevisedType && (
                            <>
                                <Form.Item
                                    name="sampleCommentRevised"
                                    label="Rapor Revize Açıklama TR"
                                    rules={[
                                        {
                                            required: false,
                                            message: "Lütfen rapor revize açıklamasını giriniz.",
                                        },
                                    ]}
                                    initialValue={this.state?.reportInfo?.sampleCommentRevised}
                                >
                                    <TextArea rows={4} placeholder="Açıklama giriniz."/>
                                </Form.Item>

                                <Form.Item
                                    name="sampleCommentRevisedEn"
                                    label="Rapor Revize Açıklama EN"
                                    rules={[
                                        {
                                            required: false,
                                            message: "Lütfen rapor revize açıklamasını giriniz.",
                                        },
                                    ]}
                                    initialValue={this.state?.reportInfo?.sampleCommentRevised_en}
                                >
                                    <TextArea rows={4} placeholder="Açıklama giriniz."/>
                                </Form.Item>
                            </>
                        )}

                        <Row gutter="16">
                            <Col span={12}>
                                <Form.Item
                                    name="reportSignatureType"
                                    label="Rapor İmza Türü"
                                    rules={[
                                        {
                                            required: false,
                                            message: "Lütfen rapor açıklamasını giriniz.",
                                        },
                                    ]}
                                    initialValue={this.state.reportSignatureType}
                                >
                                    <Radio.Group
                                        onChange={(e) =>
                                            this.setState({
                                                reportSignatureType: e.target.value,
                                            })
                                        }
                                    >
                                        <Radio value={0}>İmzasız Rapor</Radio>
                                        <Radio value={1}>İmzalı Rapor</Radio>
                                        <Radio value={2}>Kaşesiz Rapor</Radio>
                                    </Radio.Group>
                                </Form.Item>

                                {this.state.reportSignatureType > 0 && (
                                    <div
                                        style={{
                                            border: "1px solid #d9d9d9",
                                            padding: "15px",
                                            borderRadius: "2px",
                                            marginBottom: "16px",
                                        }}
                                    >
                                        <Form.Item
                                            name="reportCuSignatureUserId"
                                            label="Deney Sorumlusu"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: "Lütfen Deney Sorumlusu seçiniz",
                                                },
                                            ]}
                                            initialValue={this.state?.reportInfo?.signCUserId}
                                        >
                                            <Select
                                                showSearch
                                                placeholder="Seçiniz"
                                                optionFilterProp="children"
                                            >
                                                {userSelect}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            name="reportSignatureUserId"
                                            label="İmzalayan"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: "Lütfen İmzalayan seçiniz",
                                                },
                                            ]}
                                            initialValue={this.state?.reportInfo?.signUserId}
                                        >
                                            <Select
                                                showSearch
                                                placeholder="Seçiniz"
                                                optionFilterProp="children"
                                            >
                                                {userSelect}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                )}
                            </Col>
                            {/*
                            <Col span={12}>
                                 <Form.Item
                  name="reportEMailListType"
                  label="Raporu E-mail"
                  rules={[
                    {
                      required: false,
                      message: "Lütfen rapor açıklamasını giriniz.",
                    },
                  ]}
                  valuePropName="checked"
                  initialValue={this.state.reportEMailListType}
                >
                  <Checkbox
                    onChange={(e) =>
                      this.setState({
                        reportEMailListType: e.target.checked,
                      })
                    }
                  >
                    Gönder
                  </Checkbox>
                </Form.Item> */}
                            {/*
                  this.state.reportEMailListType && (
                    <>
                      <ReactMultiEmail
                          placeholder=""
                          emails={emails}
                          onChange={(_emails) => {
                              this.setState({ emails: _emails });
                          }}
                          validateEmail={(email) => {
                              return isEmail(email); // return boolean
                          }}
                          getLabel={(
                              email,
                              index,
                              removeEmail
                          ) => {
                              return (
                                  <div data-tag key={index}>
                                      {email}
                                      <span
                                          data-tag-handle
                                          onClick={() =>
                                              removeEmail(index)
                                          }
                                      >
                                          ×
                                      </span>
                                  </div>
                              );
                          }}
                        />
                    </>
                  )

                            </Col>
                              */}
                        </Row>

                        <Form.Item
                            name="imageFile"
                            label="Resim Ekle"
                            rules={[{required: false, message: "Lütfen resim seçiniz"}]}
                        >
                            <Upload
                                listType="picture-card"
                                fileList={this.state.fileList}
                                onPreview={this.handlePreview}
                                onChange={this.handleChange}
                                accept="image/*"
                            >
                                {this.state.fileList.length >= 5 ? null : uploadButton}
                            </Upload>
                        </Form.Item>

                        <div className="formButton">
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={this.state.btnLoading}
                            >
                                <PlusOutlined/> Rapor Oluştur
                            </Button>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    title={"Açıklama"}
                    visible={this.state.modalAcredite}
                    onCancel={() => this.setState({modalAcredite: false})}
                    okText={"Kaydet"}
                    onOk={this.acredite_save}
                    okButtonProps={{
                        loading: this.state.btnLoading,
                    }}
                    cancelText="Vazgeç"
                >
                    <p>Açıklama Ekle</p>
                    <p>Başlık Türkçe </p>
                    <Input
                        placeholder="Başlık Giriniz"
                        onChange={(e) =>
                            this.setState({
                                acrediteNameTr: e.target.value,
                            })
                        }
                        value={this.state.acrediteNameTr}
                    />
                    <p>Başlık İngilizce </p>
                    <Input
                        placeholder="Başlık Giriniz"
                        onChange={(e) =>
                            this.setState({
                                acrediteNameEn: e.target.value,
                            })
                        }
                        value={this.state.acrediteNameEn}
                    />
                    <p>Açıklama TR</p>
                    <TextArea
                        rows={4}
                        placeholder="Açıklama giriniz."
                        onChange={(e) => this.setState({acrediteDescTr: e.target.value})}
                        value={this.state.acrediteDescTr}
                    />
                    <p>Açıklama EN</p>
                    <TextArea
                        rows={4}
                        placeholder="Açıklama giriniz."
                        onChange={(e) => this.setState({acrediteDescEn: e.target.value})}
                        value={this.state.acrediteDescEn}
                    />
                </Modal>
                <Modal
                    title={"Revize"}
                    visible={this.state.modalRevizeVisible}
                    onCancel={() =>
                        this.setState({modalRevizeVisible: false, description: ""})
                    }
                    okText={"Gönder"}
                    onOk={() => this.sendRevised(1)}
                    okButtonProps={{
                        loading: this.state.btnLoading,
                    }}
                    cancelText="Vazgeç"
                >
                    <p>Revize Açıklamasını Giriniz.</p>
                    <TextArea
                        rows={4}
                        placeholder="Açıklama giriniz."
                        onChange={(e) => this.setState({description: e.target.value})}
                    />
                    <br/> <br/>
                    <b>
                        Numune revize sürecine girecektir ve işlem sonunda yeni bir revize
                        rapor oluşacaktır
                    </b>
                </Modal>
                <Modal
                    title={"Raporda Değişiklik Yap"}
                    visible={this.state.modalRevizeEditVisibled}
                    onCancel={() =>
                        this.setState({modalRevizeEditVisibled: false, description: ""})
                    }
                    okText={"Gönder"}
                    onOk={() => this.sendRevised(2)}
                    okButtonProps={{
                        loading: this.state.btnLoading,
                    }}
                    cancelText="Vazgeç"
                >
                    <p>Değişiklik Açıklamasını Giriniz.</p>
                    <TextArea
                        rows={4}
                        placeholder="Açıklama giriniz."
                        onChange={(e) => this.setState({description: e.target.value})}
                    />
                    <br/> <br/>
                    <b>Numune değişikliğe açılacaktır, varolan rapor silinecektir.</b>
                </Modal>
            </div>
        );
    }
}

export default SampleTestEdit;
