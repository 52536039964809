import React from "react";
import "../../Styles/global.css";

import Layout from "../../Components/layout/Layout";

import Dashboard from "../../Pages/Dashboard";
import Profile from "../../Pages/Profile";
import Other from "../../Pages/Other";
import Login from "../../Pages/Login/Login";
import LoginRemember from "../../Pages/Login/LoginRemember";
import ResetPassword from "../../Pages/Login/ResetPassword";
import LockScreen from "../../Pages/Login/LockScreen";
import page404 from "../../Pages/Error/404";

// Muhasebe
import Customers from "../../Pages/Customers";
import Offers from "../../Pages/Offers";
import OfferEdit from "../../Pages/Offers/OfferEdit";

import Contracts from "../../Pages/Contract";
import TestLabsPrices from "../../Pages/AnalysisPrices";

// Numune
// import QrCode from "./Pages/Sample/QrCode"
import SampleRegistration from "../../Pages/Sample/SampleRegistration";
import SampleRegistrationEdit from "../../Pages/Sample/SampleRegistration/SampleRegistrationEdit";
import SamplePrint from "../../Pages/Sample/SampleRegistration/SamplePrint";
import SampleRegistrationAdd from "../../Pages/Sample/SampleRegistration/SampleRegistrationAdd";
import SampleReport from "../../Pages/Sample/SampleReport";
import Poas from "../../Pages/Sample/Poas";
import SampleReportEdit from "../../Pages/Sample/SampleReport/SampleReportEdit";

import SampleTest from "../../Pages/Sample/SampleTest";
import SampleTestEdit from "../../Pages/Sample/SampleTest/SampleTestEdit";

import StorageAndMonitoring from "../../Pages/Sample/StorageAndMonitoring";
import SampleStatus from "../../Pages/Sample/SampleStatus";

import WorkOrders from "../../Pages/Sample/WorkOrders";
import WorkOrderEdit from "../../Pages/Sample/WorkOrders/WorkOrderEdit";
import WorkOrderAdd from "../../Pages/Sample/WorkOrders/WorkOrderAdd";
import SampleTracking from "../../Pages/Sample/SampleTracking";
// Modules
import ModulesStore from "../../Pages/Modules/Store";
import Inventory from "../../Pages/Modules/Inventory";
import RawMaterials from "../../Pages/Modules/RawMaterials";
import Announcement from "../../Pages/Modules/Announcement";
import DataTransfer from "../../Pages/Modules/DataTransfer";
import QualityManagement from "../../Pages/Modules/QualityManagement";
import DocumentManagement from "../../Pages/Modules/DocumentManagement";
import EducationModule from "../../Pages/Modules/EducationModule";
import HumanResources from "../../Pages/Modules/HumanResources";
import Log from "../../Pages/Modules/Log";
import SecurityBackup from "../../Pages/Modules/SecurityBackup";

// Tanımlamalar

import FormDesigns from "../../Pages/Definitions/FormDesigns";
import ReceiverShipper from "../../Pages/Definitions/Sample/ReceiverShipper";
import SamplerCompany from "../../Pages/Definitions/Sample/SamplerCompany";
import SampleAt from "../../Pages/Definitions/Sample/SampleAt";
import SampleGetMethod from "../../Pages/Definitions/Sample/SampleGetMethod";

import SampleTerminal from "../../Pages/Definitions/Sample/Terminal";
import SampleReportDesc from "../../Pages/Definitions/Sample/ReportDesc";
import SampleAtCategory from "../../Pages/Definitions/Sample/SampleAtCategory";
import Labs from "../../Pages/Definitions/Labs";
import Samples from "../../Pages/Definitions/Samples";
// import SampleFeatures from "./Pages/Definitions/SampleFeatures"
import SampleBrand from "../../Pages/Definitions/Samples/SampleBrand";
import SampleModel from "../../Pages/Definitions/Samples/SampleModel";
import SamplePiece from "../../Pages/Definitions/Samples/SamplePiece";
import SampleProductType from "../../Pages/Definitions/Samples/SampleProductType";
import DStore from "../../Pages/Definitions/Store";
import TestFeatures from "../../Pages/Definitions/TestFeatures";
import TestFeaturesTestCategory from "../../Pages/Definitions/TestFeatures/TestCategory";
import TestFeaturesTestDevice from "../../Pages/Definitions/TestFeatures/TestDevice";
import TestFeaturesTestMethod from "../../Pages/Definitions/TestFeatures/TestMethod";
import TestFeaturesTestOptions from "../../Pages/Definitions/TestFeatures/TestOptions";
import TestFeaturesTestType from "../../Pages/Definitions/TestFeatures/TestType";
import TestFeaturesTestUnit from "../../Pages/Definitions/TestFeatures/TestUnit";
import TestIdentification from "../../Pages/Definitions/TestIdentification";
import TestReferance from "../../Pages/Definitions/TestFeatures/TestReferance";
import TestReferanceEdit from "../../Pages/Definitions/TestFeatures/TestReferance/TestReferanceEdit";
import TestPack from "../../Pages/Definitions/TestPack";
import TestPackEdit from "../../Pages/Definitions/TestPack/TestPackEdit";
import TestPackAdd from "../../Pages/Definitions/TestPack/TestPackAdd";
import Operation from "../../Pages/Definitions/Operation";
import Products from "../../Pages/Definitions/Products";
import ReferanceStandart from "../../Pages/Definitions/ReferanceStandard";
import Regions from "../../Pages/Definitions/Regions";
import InventoryCategory from "../../Pages/Definitions/InventoryCategory";
import InventoryBrand from "../../Pages/Definitions/InventoryBrand";

import AnalysisRegistration from "../../Pages/AnalysisRegistration";

// Ayarlar
import Users from "../../Pages/Settings/Users";
import Role from "../../Pages/Settings/Role";
import NotificationRole from "../../Pages/Settings/NotificationRole";
import Widgets from "../../Pages/Settings/Widgets";
import Notification from "../../Pages/Settings/Notification";
import LabTest from "../../Pages/Settings/LabTest";
import LabTestEdit from "../../Pages/Settings/LabTest/LabTestEdit";
import LabTestDefinition from "../../Pages/Settings/LabTest/LabTestDefinition";
import Sector from "../../Pages/Settings/Sector";

// Raporlama
import TestLabs from "../../Pages/Reporting/TestLabs";
import DetailedReport from "../../Pages/Reporting/DetailedReport";
import PersonelPerformanceReport from "../../Pages/Reporting/PersonelPerformance";

import Helper from "../../Helper";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

const control = () => {
  const token = Helper.getlocalStorage("Token");
  if (token) {
    window.location.href = "/dashboard";
  } else {
    window.location.href = "/login";
  }
  return null;
};

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/" exact strict component={control}></Route>
          <Route path="/login" exact strict component={Login}></Route>
          <Route
            path="/login-remember"
            exact
            strict
            component={LoginRemember}
          ></Route>
          <Route
            path="/resetpassword/:token"
            exact
            strict
            component={ResetPassword}
          ></Route>
          <Route
            path="/lock-screen"
            exact
            strict
            component={LockScreen}
          ></Route>
          <Layout>
            {/* Dashboard - Profile */}
            <Route path="/dashboard" exact strict component={Dashboard}></Route>
            <Route path="/profile" exact strict component={Profile}></Route>
            <Route path="/other" exact strict component={Other}></Route>

            {/* Muhasebe */}
            <Route path="/customers" exact strict component={Customers}></Route>
            <Route path="/offers" exact strict component={Offers}></Route>
            <Route
              path="/offers/edit"
              exact
              strict
              component={OfferEdit}
            ></Route>
            <Route path="/contracts" exact strict component={Contracts}></Route>

            {/* Numune */}
            {/* <Route path="/sample/qr-code" exact strict component={QrCode}></Route> */}
            <Route
              path="/sample/sample-registration"
              exact
              strict
              component={SampleRegistration}
            ></Route>
            <Route
              path="/sample/sample-registration/edit/:sampleId"
              exact
              strict
              component={SampleRegistrationEdit}
            ></Route>
            <Route
              path="/sample/sample-print/:sampleId"
              exact
              strict
              component={SamplePrint}
            ></Route>
            <Route
              path="/sample/sample-registration/add"
              exact
              strict
              component={SampleRegistrationAdd}
            ></Route>
            <Route
              path="/sample/sample-report"
              exact
              strict
              component={SampleReport}
            ></Route>
            <Route
              path="/sample/poas"
              exact
              strict
              component={Poas}
            ></Route>
            <Route
              path="/sample/sample-report/edit/:sampleId"
              exact
              strict
              component={SampleReportEdit}
            ></Route>
            <Route
              path="/sample/sample-test"
              exact
              strict
              component={SampleTest}
            ></Route>
            <Route
              path="/sample/sample-test/edit/:sampleId"
              exact
              strict
              component={SampleTestEdit}
            ></Route>

            <Route
              path="/sample/sample-tracking"
              exact
              strict
              component={SampleTracking}
            ></Route>
            <Route
              path="/sample/work-orders"
              exact
              strict
              component={WorkOrders}
            ></Route>
            <Route
              path="/sample/work-orders/add"
              exact
              strict
              component={WorkOrderAdd}
            ></Route>
            <Route
              path="/sample/work-orders/edit/:reportno"
              exact
              strict
              component={WorkOrderEdit}
            ></Route>

            <Route
              path="/sample/storage-and-monitoring"
              exact
              strict
              component={StorageAndMonitoring}
            ></Route>
            <Route
              path="/sample/sample-status"
              exact
              strict
              component={SampleStatus}
            ></Route>

            {/* Moduller */}
            <Route
              path="/modules/store"
              exact
              strict
              component={ModulesStore}
            ></Route>
            <Route
              path="/modules/inventory"
              exact
              strict
              component={Inventory}
            ></Route>
            <Route
              path="/modules/raw-materials"
              exact
              strict
              component={RawMaterials}
            ></Route>
            <Route
              path="/modules/announcement"
              exact
              strict
              component={Announcement}
            ></Route>
            <Route
              path="/modules/data-transfer"
              exact
              strict
              component={DataTransfer}
            ></Route>
            <Route
              path="/modules/quality-management"
              exact
              strict
              component={QualityManagement}
            ></Route>
            <Route
              path="/modules/document-management"
              exact
              strict
              component={DocumentManagement}
            ></Route>
            <Route
              path="/modules/education-module"
              exact
              strict
              component={EducationModule}
            ></Route>
            <Route
              path="/modules/human-resources"
              exact
              strict
              component={HumanResources}
            ></Route>
            <Route path="/modules/log" exact strict component={Log}></Route>
            <Route
              path="/modules/security-backup"
              exact
              strict
              component={SecurityBackup}
            ></Route>

            {/* Ayarlar */}
            {/* Tanımlamalar */}
            <Route
              path="/definitions/form-designs"
              exact
              strict
              component={FormDesigns}
            ></Route>
            {/* Tanımlamalar */}
            <Route
              path="/definitions/sample/receiver-shipper"
              exact
              strict
              component={ReceiverShipper}
            ></Route>
            <Route
              path="/definitions/sample/sampler-company"
              exact
              strict
              component={SamplerCompany}
            ></Route>
            <Route
              path="/definitions/sample/sampled-at"
              exact
              strict
              component={SampleAt}
            ></Route>
            <Route
              path="/definitions/sample/get-method"
              exact
              strict
              component={SampleGetMethod}
            ></Route>
            <Route
              path="/definitions/sample/terminal"
              exact
              strict
              component={SampleTerminal}
            ></Route>
            <Route
              path="/definitions/sample/report-desc"
              exact
              strict
              component={SampleReportDesc}
            ></Route>
            <Route
              path="/definitions/sample/sampled-at-category"
              exact
              strict
              component={SampleAtCategory}
            ></Route>
            <Route
              path="/definitions/labs"
              exact
              strict
              component={Labs}
            ></Route>
            {/* <Route path="/definitions/samples" exact strict component={Samples}></Route>
							<Route path="/definitions/samples/sample-brand" exact strict component={SampleBrand}></Route>
							<Route path="/definitions/samples/sample-model" exact strict component={SampleModel}></Route>
							<Route path="/definitions/samples/sample-piece" exact strict component={SamplePiece}></Route>
							<Route path="/definitions/samples/sample-product-type" exact strict component={SampleProductType}></Route> */}
            <Route
              path="/definitions/store"
              exact
              strict
              component={DStore}
            ></Route>
            <Route
              path="/definitions/test-features"
              exact
              strict
              component={TestFeatures}
            ></Route>
            <Route
              path="/definitions/test-features/test-category"
              exact
              strict
              component={TestFeaturesTestCategory}
            ></Route>
            <Route
              path="/definitions/test-features/test-device"
              exact
              strict
              component={TestFeaturesTestDevice}
            ></Route>
            <Route
              path="/definitions/test-features/test-method"
              exact
              strict
              component={TestFeaturesTestMethod}
            ></Route>
            <Route
              path="/definitions/test-features/test-options"
              exact
              strict
              component={TestFeaturesTestOptions}
            ></Route>
            <Route
              path="/definitions/test-features/test-type"
              exact
              strict
              component={TestFeaturesTestType}
            ></Route>
            <Route
              path="/definitions/test-features/test-unit"
              exact
              strict
              component={TestFeaturesTestUnit}
            ></Route>
            <Route
              path="/definitions/test-features/test-ref"
              exact
              strict
              component={TestReferance}
            ></Route>
            <Route
              path="/definitions/test-features/test-ref/edit/:testId"
              exact
              strict
              component={TestReferanceEdit}
            ></Route>
            <Route
              path="/definitions/test-identification"
              exact
              strict
              component={TestIdentification}
            ></Route>
            <Route
              path="/definitions/test-pack"
              exact
              strict
              component={TestPack}
            ></Route>
            <Route
              path="/definitions/test-pack/edit/:testpackid"
              exact
              strict
              component={TestPackEdit}
            ></Route>
            <Route
              path="/definitions/test-pack/add"
              exact
              strict
              component={TestPackAdd}
            ></Route>
            <Route
              path="/definitions/products"
              exact
              strict
              component={Products}
            ></Route>
            <Route
              path="/definitions/referance-standard"
              exact
              strict
              component={ReferanceStandart}
            ></Route>
            <Route
              path="/definitions/regions"
              exact
              strict
              component={Regions}
            ></Route>
            <Route
              path="/definitions/operation"
              exact
              strict
              component={Operation}
            ></Route>
            <Route
              path="/definitions/inventory-category"
              exact
              strict
              component={InventoryCategory}
            ></Route>
            <Route
              path="/definitions/inventory-brand"
              exact
              strict
              component={InventoryBrand}
            ></Route>
            <Route
              path="/settings/users"
              exact
              strict
              component={Users}
            ></Route>
            <Route path="/settings/role" exact strict component={Role}></Route>
            <Route
              path="/settings/notification-role"
              exact
              strict
              component={NotificationRole}
            ></Route>
            <Route
              path="/settings/widgets"
              exact
              strict
              component={Widgets}
            ></Route>
            <Route
              path="/settings/notification"
              exact
              strict
              component={Notification}
            ></Route>
            <Route
              path="/settings/sector"
              exact
              strict
              component={Sector}
            ></Route>

            <Route
              path="/settings/lab-test"
              exact
              strict
              component={LabTest}
            ></Route>
            <Route
              path="/settings/lab-test/edit/:testId"
              exact
              strict
              component={LabTestEdit}
            ></Route>
            <Route
              path="/settings/lab-test/definition"
              exact
              strict
              component={LabTestDefinition}
            ></Route>

            <Route
              path="/analysis-results-registration/:sampleId"
              exact
              strict
              component={AnalysisRegistration}
            ></Route>

            <Route
              path="/reporting/test-labs"
              exact
              strict
              component={TestLabs}
            ></Route>

            <Route
              path="/analysis-prices"
              exact
              strict
              component={TestLabsPrices}
            ></Route>

            <Route
              path="/reporting/detail-report"
              exact
              strict
              component={DetailedReport}
            ></Route>
            <Route
              path="/reporting/personel-performance-report"
              exact
              strict
              component={PersonelPerformanceReport}
            ></Route>
          </Layout>

          <Route path="*" component={page404} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
