// Customer Services
export const Customer = {
  create: "customer/create",
  delete: "customer/delete",
  getAll: "customer/getAll", // /:limit/:page
  getOne: "customer/getOne", // /:customer_id
  mail: "customer/mail", // /:customer_id
  status: "customer/status",
  update: "customer/update",
};
// Laboratory Services
export const Laboratory = {
  create: "laboratory/create",
  delete: "laboratory/delete",
  getAll: "laboratory/getAll", // /:limit/:page
  getOne: "laboratory/getOne", // /:laboratory_id
  status: "laboratory/status",
  update: "laboratory/update",
};
// Logs Services
export const Logs = "log/getAll"; // /:limit/:page/:date
// Operation Services
export const Operation = {
  create: "operation/create",
  delete: "operation/delete",
  getAll: "operation/getAll", // /:limit/:page
  getOne: "operation/getOne", // /:operation_id
  status: "operation/status",
  update: "operation/update",
};
// Product Services
export const Product = {
  create: "product/create",
  delete: "product/delete",
  getAll: "product/getAll", // /:limit/:page
  getOne: "product/getOne", // /:product_id
  status: "product/status",
  update: "product/update",
};
// Region Services
export const Region = {
  create: "region/create",
  delete: "region/delete",
  getAll: "region/getAll", // /:limit/:page
  getOne: "region/getOne", // /:region_id
  status: "region/status",
  update: "region/update",
};
// Sector Services
export const Sector = {
  create: "sector/create",
  delete: "sector/delete",
  getAll: "sector/getAll", // /:limit/:page
  getOne: "sector/getOne", // /:sector_id
  status: "sector/status",
  update: "sector/update",
};
// Store Services
export const Store = {
  create: "store/create",
  getAll: "store/getAll", // /:limit/:page
  getOne: "store/getOne", // /:store_id
  part: "store/storePart",
  update: "store/update",
};
// Test Services
export const Test = {
  create: "test/create",
  delete: "test/delete",
  getAll: "test/getAll", // /:limit/:page
  getOne: "test/getOne", // /:test_id
  status: "test/status",
  update: "test/update",
  refUpdate: "test/testRef",
  getProducts: "test/getProduct",
  // refUpdate:"test/testRef",
  // getProducts:"test/getProduct", // conflict hatasi almistik 31.07.21 23:45
  getTestLabs: "report/tests-lab",
  setPrice:"test/price"

};
// Test Method Services
export const TestMethod = {
  create: "test/method/create",
  delete: "test/method/delete",
  getAll: "test/method/getAll", // /:limit/:page
  getOne: "test/method/getOne", // /:test_method_id
  status: "test/method/status",
  update: "test/method/update",
};
// Test Pack Services
export const TestPack = {
  create: "test/pack/create",
  delete: "test/pack/delete",
  getAll: "test/pack/getAll", // /:limit/:page
  getOne: "test/pack/getOne", // /:pack_id
  status: "test/pack/status",
  update: "test/pack/update",
};
// Test Status Services
export const TestStatus = {
  create: "test/status/create",
  delete: "test/status/delete",
  getAll: "test/status/getAll", // /:limit/:page
  getOne: "test/status/getOne", // /:test_status_id
  status: "test/status/status",
  update: "test/status/update",
};
// Test Unit Services
export const TestUnit = {
  create: "test/unit/create",
  delete: "test/unit/delete",
  getAll: "test/unit/getAll", // /:limit/:page
  getOne: "test/unit/getOne", // /:test_unit_id
  status: "test/unit/status",
  update: "test/unit/update",
};
// Test Options Services
export const TestOptions = {
  create: "test/option/create",
  delete: "test/option/delete",
  getAll: "test/option/getAll", // /:limit/:page
  getOne: "test/option/getOne", // /:test_unit_id
  status: "test/option/status",
  update: "test/option/update",
};
// Test Device Services
export const TestDevice = {
  create: "test/device/create",
  delete: "test/device/delete",
  update: "test/device/update",
  getAll: "test/device/getAll",
  getOne: "test/device/getOne",
  inventory: "test/device/inventory/getAll",
  test: "test/device/test/getAll",
};
// User Services
export const User = {
  register: "user/register",
  update: "/user/update",
  login: "login",
  getOne: "/user/getOne",
  getAll: "user/getAll",
  permGetAll: "user/perm/getAll",
  profile: "/user/profile",
  profileUpdate: "/user/profile/update",
  photo: "/user/profile/photo",
  signature: "/user/profile/signature",
  signatureSecond: "/user/profile/signature2",
  files: "/user/files",
  status: "/user/status",
  delete: "/user/delete",
  password: "user/password",
  forgot: "/forgot_password",
  reset: "/resetpassword",
};

// Inventory Services
export const Inventory = {
  create: "inventory/create",
  delete: "inventory/delete",
  getAll: "inventory/getAll",
  getOne: "inventory/getOne",
  status: "inventory/status",
  update: "inventory/update",
};

// Inventory Brand  Services
export const InventoryBrand = {
  create: "inventory/brand/create",
  delete: "inventory/brand/delete",
  getAll: "inventory/brand/getAll",
  getOne: "inventory/brand/getOne",
  status: "inventory/brand/status",
  update: "inventory/brand/update",
};

// Inventory Category  Services
export const InventoryCategory = {
  create: "inventory/cat/create",
  delete: "inventory/cat/delete",
  getAll: "inventory/cat/getAll",
  getOne: "inventory/cat/getOne",
  status: "inventory/cat/status",
  update: "inventory/cat/update",
};
// Work Order Services
export const WorkOrder = {
  create: "workOrder/create",
  update: "workOrder/update",
  getAll: "workOrder/getAll",
  getOne: "workOrder/getOne",
  details: "workOrder/detail",
  workfinished: "workOrder/finish",
};

export const TrendAnalytics = {
  check: "trend-analytics-check"
}

// Sample  Services
export const Sample = {
  create: "sample/create",
  getAll: "sample/getAll",
  poasGetAll: "sample/poas/getAll",
  poasDetail: "sample/poas/detail",
  poasControl: "sample/poas/control",
  poasSend: "sample/poas/result/send",
  getLocation: "sample/get_location",
  getLocationOne:"get_location/getOne",
  getOne: "sample/getOne",
  getPart: "sample/get_part",
  getPartCat: "sample/get_part_cat",
  getStock: "sample/stock",
  updateStock: "sample/stock/update",
  update: "sample/update",
  workOrderByCustomer: "sample/workOrderByCustomer",
  getMethod: "/sample/get_method",
  selectQr: "/sample/qrcode",
  selectPerson: "/sample/get_person",
  selectCustomer: "/sample/get_person_customer",
  getTests: "/sample/getTests",
  setTests: "/sample/postTest",
  sendRevised: "/sample/sendRevisionTest",
  sendReport: "/sample/createReport",
  testStatus: "/sample/test/status",
  startTest: "/sample/test/status",
  getPdf: "getPdf",
  getBlobPdf: "blob/get",
  reportDesc: "/sample/report/desc",
  reportType: "/sample/report/type",
  sendReportMail:"/sample/report/mail",
  personUpdate: "/person/update",
  personCreate: "/person/create",
  personDelete: "/person/delete",
  remove: "/sample/remove",
  terminalUpdate: "/get_location/update",
  terminalCreate: "/get_location/create",
  terminalDelete: "/get_location/delete",

  sampleMethodCreate: "/get_method/create",
  sampleMethodUpdate: "/get_method/update",
  sampleMethodDelete: "/get_method/delete",

  sampleAtUpdate: "/get_part/update",
  sampleAtCreate: "/get_part/create",
  sampleAtDelete: "/get_part/delete",
  sampleAtCategoryUpdate: "get_part_cat/update",
  sampleAtCategoryCreate: "get_part_cat/create",
  sampleAtCategoryDelete: "get_part_cat/delete",
  reportDescList: "sample/report/desc ",
  reportDescCreate: "sample/report/desc/create",
  reportDescUpdate: "sample/report/desc/update",
  reportDescDelete: "sample/report/desc/delete",
  tankbytankupdate: "sample/tank/desc/update",
  getPersonCustomer:"sample/get_person_customer",
  getPersonCustomerCreate:"get-person-customer/create",
  getPersonCustomerUpdate:"get-person-customer/update",
  getPersonCustomerDelete:"get-person-customer/delete",
  sampletracking:"sample/typeAll"
};
export const Menu = {
  getAll: "menu/getAll",
  permAll: "menu/definition/perm",
  permSelect: "menu/perm/getAll",
};

export const TestLab = {
  create: "/test/labTest/create",
  delete: "/test/labTest/delete",
  selectAll: "/test/labTest/getAll",
  select: "/test/labTest/getOne",
};
export const Notification = {
  getAll: "notification/getAll",
  update: "notification/update",
  typeAll:"notification/type",
  permAll:"notification/perm/getAll",
  permSave:"notification/perm/create",
  getPermAll:"notification/perm/GetList"
};

export const Announcement = {
  create: "/announcement/create",
  dashboard: "/announcement/dashboard",
  getAll: "/announcement/getAll",
  update: "/announcement/update",
};

export const Dashboard = {
  select: "dashboard/index",
  generalReport: "dashboard/general-report",
  monthtest: "dashboard/graphic/monthly-test",
  sample: "dashboard/graphic/sample",
  test: "dashboard/graphic/test",
  sampleTest: "/dashboard/graphic/sample-test",
  statistic: "/dashboard/graphic/box",
  workorder: "dashboard/work-order",
  followworkorderlist: "dashboard/work-order-chase",
  followworkordercreate: "workOrder/chase ",
  createLink: "/dashboard/create-link",
  detailReports:"/dashboard/general/test",
  teststatistic:"/dashboard/general/test-statistics",
  teststatisticCustomer:"/dashboard/general/customer-statistics",
  teststatisticProduct:"/dashboard/general/product-statistics",
  personelperformancelist:"/dashboard/general/personnelPerformanceStatistics",
  personelperformancedetails:"/dashboard/general/personnelPerformanceStatisticsDetail"
};

export const ReferanceStandart = {
  create: "/reference-standard/create",
  delete: "/reference-standard/delete",
  getAll: "/reference-standard/getAll",
  getOne: "/reference-standard/getOne",
  status: "/reference-standard/status",
  update: "/reference-standard/update",
};
