import React, { Component } from "react";
import axios from "axios";
import * as Endpoint from "../../../Provider/Config/EndpointUrl";
import { baseAPIUrl } from "../../../Provider/Config/Config";

import { NavLink } from "react-router-dom";

import Table from "../../../Components/UI/Table";
import PageHeader from "../../../Components/UI/PageHeader";

import {
  Modal,
  Button,
  Tooltip,
  Card,
  Form,
  Input,
  Select,
  Space,
  Tag,
  Spin,
  Menu,
  Dropdown,
} from "antd";

import { PlusOutlined, DownOutlined } from "@ant-design/icons";
import _ from "lodash";
import moment from "moment";
import Helper from "../../../Helper";
import DataService from "../../../Provider/Service/DataService";
import RangeButton from "./RangeItem";
import PrintComponent from "../WorkOrders/Print/print";
const { Option } = Select;
const statusArray = [
  {
    id: 0,
    name: "Bekliyor",
    color: "#fd6205",
  },
  {
    id: 1,
    name: "Okundu",
    color: "#fe9835",
  },
  {
    id: 2,
    name: "İşlemde",
    color: "#ffce0b",
  },
  {
    id: 3,
    name: "Sonuçlandı",
    color: "#1abc9c",
  },
  {
    id: 4,
    name: "Numune Analizde",
    color: "#9894ff",
  },
  {
    id: 5,
    name: "Rapolanacak",
    color: "#fd54ff",
  },
  {
    id: 6,
    name: "Raporlandı",
    color: "rgb(28, 180, 81)",
  },
  {
    id: 7,
    name: "Muhasebe",
    color: "#9bd006",
  },
  {
    id: 8,
    name: "İşlem Tamamlandı",
    color: "#1cb451",
  },
];
const statusFilterArray = [
  {
    value: 0,
    text: "Bekliyor",
  },
  {
    value: 1,
    text: "Okundu",
  },
  {
    value: 2,
    text: "İşlemde",
  },
  {
    value: 3,
    text: "Sonuçlandı",
  }
];
class index extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      searchText: "",
      searchedColumn: "",
      tableColumns: [
        {
          title: "Sap Id",
          dataIndex: "poInsplot",
          key: "poInsplot",
          sorter: (a, b) => a.poInsplot.localeCompare(b.poInsplot),
          ...Helper.getColumnSearchProps(
            "poInsplot",
            this.handleSearch,
            this.handleReset,
            "Sap Id"
          ),
        },
        {
          title: "Lokasyon Tanımı ( Terminal )",
          dataIndex: "getLocation",
          key: "getLocation",
          ...Helper.getColumnSearchProps(
            "getLocation",
            this.handleSearch,
            this.handleReset,
            "Lokasyon Tanımı ( Terminal )"
          ),
        },
        {
          title: "Malzeme Tanım",
          dataIndex: "poMalzemeTanim",
          key: "poMalzemeTanim",
          ...Helper.getColumnSearchProps(
            "poMalzemeTanim",
            this.handleSearch,
            this.handleReset,
            "Malzeme Tanım"
          ),
        },
        {
          title: "Yetkili İsim",
          dataIndex: "poContactName",
          key: "poContactName",
          ...Helper.getColumnSearchProps(
            "poContactName",
            this.handleSearch,
            this.handleReset,
            "Yetkili İsim"
          ),
        },
        {
          title: "Yetkili Mail",
          dataIndex: "poContactEmail",
          key: "poContactEmail",
          ...Helper.getColumnSearchProps(
            "poContactEmail",
            this.handleSearch,
            this.handleReset,
            "Yetkili Mail"
          ),
        },
        {
          title: "Yetkili Telefon",
          dataIndex: "poContactPhone",
          key: "poContactPhone",
          ...Helper.getColumnSearchProps(
            "poContactPhone",
            this.handleSearch,
            this.handleReset,
            "Yetkili Telefon"
          ),
        },
        {
          title: "Durum",
          dataIndex: "status_id",
          key: "status_id",
          sorter: (a, b) => a.status_id - b.status_id,
          filters: statusFilterArray,
          onFilter: (value, record) => {
            return record.status_id === value;
          },
          render: (status_id, record) => {
            const status = _.find(statusArray, { id: status_id });
            return (
              <Tag color={status?.color || "red"} key={status_id}>
                {status?.name || "Bulunamadı"}
              </Tag>
            );
          },
        },
        {
          title: "Seçenekler",
          dataIndex: "options",
          key: "options",
          render: (text, record) => {
            return (
              <Space size="middle">
                <Dropdown overlay={this._renderMenu(record)}>
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    Seçenekler <DownOutlined />
                  </a>
                </Dropdown>
              </Space>
            );
          },
        },
      ],
      infoModal:false,
      details:[],
      data: [],
      tableLoading: false,
      pagination: {
        current: 1,

        total: 10,
      },
    };
  }
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  componentDidMount() {
    this.getData();
  }
  getData = async (date = [moment().add(-30, "day"), moment()]) => {
    try {
      this.setState({ loading: true });

      const result = await DataService.Sample.poasGetAll(
        0,
        0,
        moment(date[0]).unix(),
        moment(date[1]).unix()
      );
      if (result.status) {
        this.setState({ data: result.response.data, loading: false });
      } else {
        this.setState({ loading: false });
        Helper.ErrorMessage(result.message);
      }
    } catch (error) {
      console.log("error", error);
      Helper.ErrorMessage(error.toString());
      this.setState({ loading: false });
    }
  };


  getDetail = async (id) => {
    try {
      this.setState({loading: true});
      const result = await DataService.Sample.poasDetails(id);
      if (result.status) {
        this.setState({
          loading: false,
          infoModal: true,
          details: result?.data,
        });
      } else {
        this.setState({loading: false});
        Helper.ErrorMessage(result?.message || "Bir hata meydana geldi.");
      }
    } catch (error) {
      this.setState({loading: false});
      console.log("error", error);
      Helper.ErrorMessage("Sistemsel bir hata meydana geldi");
    }
  };


  _renderMenu = ({id}) => (
    <Menu>
      <Menu.Item key="0">
        <Button
            shape="round"
            type="primary"
            onClick={() => this.getDetail(id)}
        >
          Detay
        </Button>
      </Menu.Item>
    </Menu>
  );
  sendReportMailMessage = ({ sampleId, fileName }) => {
    Helper.alertMessage({
      title: "Mail Gönder",
      message: "Raporu  mail olarak göndermek istiyor musunuz?",

      onDone: () => this.senReportMail({ sampleId, fileName }),
    });
  };
  senReportMail = ({ sampleId, fileName }) => {
    this.setState({ loading: true });
    DataService.Sample.sendReportMail({ sampleId, fileName })
      .then((result) => {
        this.setState({ loading: false });
        if (result.status) {
          Helper.SuccessMessage(result.message);
          this.getData();
        } else {
          Helper.ErrorMessage(result.message);
          this.getData();
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        Helper.ErrorMessage(error.toString());
      });
  };
  handleTableChange = (pagination, filters, sorter) => {
    console.log("handleTableChange", {
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
    this.getData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };

  render() {
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Poas Numune" />
        </div>

        <Spin spinning={this.state.loading}>
          <Card bordered={false} className="cardTable">

            <Modal
                title="Detay"
                visible={this.state?.infoModal || false}
                onOk={() => this.setState({infoModal: false})}
                onCancel={() => this.setState({infoModal: false})}
                width={1200}
                footer=""
            >
                {this.state?.details?.length > 0 ? (
                    <Table
                        columns={[
                            {
                                title: "Test Adı",
                                dataIndex: "testName",
                                key: "testName",
                            },
                            {
                                title: "Test Metodu",
                                dataIndex: "testMethod",
                                key: "testMethod",
                            },
                            {
                                title: "Operatör",
                                dataIndex: "inspectionOperator",
                                key: "inspectionOperator",
                            },
                            {
                                title: "Karakteristik",
                                dataIndex: "inspectionCharacteristic",
                                key: "inspectionCharacteristic",
                            },
                            {
                                title: "İç Mikro",
                                dataIndex: "internalMic",
                                key: "internalMic",
                            },
                            {
                                title: "Bilgi Alanı 1",
                                dataIndex: "infoField1",
                                key: "infoField1",
                            },
                            {
                                title: "Bilgi Alanı 2",
                                dataIndex: "infoField2",
                                key: "infoField2",
                            },
                            {
                                title: "Bilgi Alanı 3",
                                dataIndex: "infoField3",
                                key: "infoField3",
                            }
                        ]}
                        data={this.state?.details}
                        pagination={false}
                    />
                ) : (
                    <div>Detay bilgisi bulunamadı.</div>
                )}
            </Modal>

            <RangeButton onClick={this.getData} rangeLimit={1} />
            <Table
              columns={this.state.tableColumns}
              data={this.state.data}
              pagination={true}
            />
          </Card>
        </Spin>
      </div>
    );
  }
}

export default index;
