import React, { Component } from 'react';

import PageHeader from '../../Components/UI/PageHeader';

import "./index.css"

import { Card, Steps }  from 'antd';

const {Step} = Steps;

class index extends Component {
    state = {
        stepCurrent: 0,
    };

    _changeStep = stepCurrent => {
       // console.log('_changeStep:', stepCurrent);
        this.setState({ stepCurrent });
    };

    render() {

        const { stepCurrent } = this.state;

        return (
            <div>
                <div className="pageHeaderWrapper">
                    <PageHeader title="Other" />
                </div>

                <Card>

                    <div className="antStepsContainer">
                        <Steps current={stepCurrent} onChange={this._changeStep}>
                            <Step title="Step 1" />
                            <Step title="Step 2" />
                            <Step title="Step 3" />
                            <Step title="Step 4" />
                        </Steps>
                    </div>

                    <div className="antStepsContent">
                        {stepCurrent === 0 && (
                            0
                        )}
                        {stepCurrent === 1 && (
                            1
                        )}
                        {stepCurrent === 2 && (
                            2
                        )}
                        {stepCurrent === 3 && (
                            3
                        )}
                    </div>
                </Card>
            </div>
        );
    }
}

export default index;