import React, { Component } from "react";
import "../index.css";
import { Redirect } from "react-router-dom";
import PageHeader from "../../../../Components/UI/PageHeader";

import Printer from "./print";

import { Card, Row, Col, Spin } from "antd";

import dateTimeMoment from "moment";
import Helper from "../../../../Helper";
import DataService from "../../../../Provider/Service/DataService";
import _ from "lodash";

export default class SamplePrint extends Component {
  state = {
    loading: true,
    customerId: "",
    name: "",
    productName:"",
    registryNo:"",
    getLocation: "",
    getAcceptanceDate: "",
    customerName: "",
    sampleStock: [],
  };
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    const {
      match: { params },
    } = this.props;
    DataService.Sample.SelectQrCode(params.sampleId)
      .then((result) => {
        //console.log('result',result);
        if (!result.status) {
          Helper.ErrorMessage(result.message);
          return;
        }
        if (result.data.sampleStock.length == 0) {
          Helper.warningMessage("Numune bilgisi bulunamadığı için barkod etiketi oluşturulamadı.");
          this.setState({ redirect: "/sample/sample-registration" });
          return;
        }

        this.setState({ loading: false, ...result.data });
      })
      .catch((err) => console.log("err", err));
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Numune Barkod Yazdırma" />
        </div>
        <Spin spinning={this.state.loading} delay={500}>
          <Row gutter={16}>
              {this.state.sampleStock.map((item, index) => {
                return (
                  <Col md={6} style={{marginBottom: '16px'}}>
                    <Card bordered={false} className="">
                      <Printer
                        key={index}
                        value={item.stock_id}
                        title="Yazdır"
                        customer={this.state.customerName}
                        name={this.state.productName}
                        registryNo={this.state.registryNo}
                        sampleLocation={this.state.getLocation}
                        dateDelivery={dateTimeMoment(
                          this.state.getAcceptanceDate
                        ).format("LL")}
                        stock={item}
                      />
                    </Card>
                  </Col>
                );
              })}
          </Row>
        </Spin>
      </div>
    );
  }
}
