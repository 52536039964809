import React, {Component} from "react";
import axios from "axios";
import * as Endpoint from "../../../Provider/Config/EndpointUrl";
import {baseAPIUrl} from "../../../Provider/Config/Config";

import {NavLink} from "react-router-dom";

import Table from "../../../Components/UI/Table";
import PageHeader from "../../../Components/UI/PageHeader";

import {
    Modal,
    Button,
    Tooltip,
    Card,
    Form,
    Input,
    Select,
    Space,
    Tag,
    Spin,
    Menu,
    Dropdown,
} from "antd";

import {PlusOutlined, DownOutlined} from "@ant-design/icons";
import _ from "lodash";
import moment from "moment";
import Helper from "../../../Helper";
import DataService from "../../../Provider/Service/DataService";
import RangeButton from "./RangeItem";

const {Option} = Select;
const statusArray = [
    {
        id: 0,
        name: "Talep Alındı",
        color: "#fd6205",
    },
    {
        id: 1,
        name: "Numune Yolda",
        color: "#fe9835",
    },
    {
        id: 2,
        name: "Numune Ulaştı",
        color: "#ffce0b",
    },
    {
        id: 3,
        name: "Ödeme Bekliyor",
        color: "#1abc9c",
    },
    {
        id: 4,
        name: "Numune Analizde",
        color: "#9894ff",
    },
    {
        id: 5,
        name: "Rapolanacak",
        color: "#fd54ff",
    },
    {
        id: 6,
        name: "Raporlandı",
        color: "rgb(28, 180, 81)",
    },
    {
        id: 7,
        name: "Muhasebe",
        color: "#9bd006",
    },
    {
        id: 8,
        name: "İşlem Tamamlandı",
        color: "#1cb451",
    },
];
const statusFilterArray = [
    {
        value: 0,
        text: "Talep Alındı",
    },
    {
        value: 1,
        text: "Numune Yolda",
    },
    {
        value: 2,
        text: "Numune Ulaştı",
    },
    {
        value: 3,
        text: "Ödeme Bekliyor",
    },
    {
        value: 4,
        text: "Numune Analizde",
    },
    {
        value: 5,
        text: "Rapolanacak",
    },
    {
        value: 6,
        text: "Raporlandı",
    },
    {
        value: 7,
        text: "Muhasebe",
        color: "#9bd006",
    },
    {
        value: 8,
        text: "İşlem Tamamlandı",
    },
];

class index extends Component {
    constructor() {
        super();
        this.state = {
            loading: true,
            searchText: "",
            searchedColumn: "",
            tableColumns: [
                {
                    title: "Rapor No",
                    dataIndex: "report_no",
                    key: "report_no",
                    sorter: (a, b) => a.report_no.localeCompare(b.report_no),
                    ...Helper.getColumnSearchProps(
                        "report_no",
                        this.handleSearch,
                        this.handleReset,
                        "Rapor No"
                    ),
                },
                {
                    title: "Rapor Tipi",
                    dataIndex: "reportTypeName",
                    key: "reportTypeName",
                    sorter: (a, b) => a.reportTypeName.localeCompare(b.reportTypeName),
                    ...Helper.getColumnSearchProps(
                        "reportTypeName",
                        this.handleSearch,
                        this.handleReset,
                        "Rapor Tipi"
                    ),
                },
                {
                    title: "Numunenin Alındığı Yer",
                    dataIndex: "get_part",
                    key: "get_part",
                    sorter: (a, b) => a.get_part.localeCompare(b.get_part),
                    ...Helper.getColumnSearchProps(
                        "get_part",
                        this.handleSearch,
                        this.handleReset,
                        "Numunenin Alındığı Yer"
                    ),
                },
                {
                    title: "Müşteri",
                    dataIndex: "customerName",
                    key: "customerName",
                    sorter: (a, b) => a.customerName.localeCompare(b.customerName),
                    ...Helper.getColumnSearchProps(
                        "customerName",
                        this.handleSearch,
                        this.handleReset,
                        "Müşteri"
                    ),
                },
                {
                    title: "İş Kolu",
                    dataIndex: "sectorName",
                    key: "sectorName",
                    sorter: (a, b) => a.sectorName.localeCompare(b.sectorName),
                    ...Helper.getColumnSearchProps(
                        "sectorName",
                        this.handleSearch,
                        this.handleReset,
                        "İş Kolu"
                    ),
                },
                {
                    title: "Ürün",
                    dataIndex: "productName",
                    key: "productName",
                    sorter: (a, b) => a.productName.localeCompare(b.productName),
                    ...Helper.getColumnSearchProps(
                        "productName",
                        this.handleSearch,
                        this.handleReset,
                        "Ürün"
                    ),
                },
                {
                    title: "Operasyon",
                    dataIndex: "operationName",
                    key: "operationName",
                    sorter: (a, b) => a.operationName.localeCompare(b.operationName),
                    ...Helper.getColumnSearchProps(
                        "operationName",
                        this.handleSearch,
                        this.handleReset,
                        "Operasyon"
                    ),
                },
                {
                    title: "Paket İsmi",
                    dataIndex: "packName",
                    key: "packName",
                    sorter: (a, b) => a.packName.localeCompare(b.packName),
                    ...Helper.getColumnSearchProps(
                        "packName",
                        this.handleSearch,
                        this.handleReset,
                        "Paket İsmi"
                    ),
                },
                {
                    title: "LAB",
                    dataIndex: "labCode",
                    key: "labCode",
                    sorter: (a, b) => a.labCode.localeCompare(b.labCode),
                    ...Helper.getColumnSearchProps(
                        "labCode",
                        this.handleSearch,
                        this.handleReset,
                        "LAB"
                    ),
                },
                {
                    title: "Kullanıcı",
                    dataIndex: "actionUserFullName",
                    key: "actionUserFullName",
                    sorter: (a, b) =>
                        a.actionUserFullName.localeCompare(b.actionUserFullName),
                    ...Helper.getColumnSearchProps(
                        "actionUserFullName",
                        this.handleSearch,
                        this.handleReset,
                        "Kullanıcı"
                    ),
                },
                {
                    title: "Durum",
                    dataIndex: "status_id",
                    key: "status_id",
                    filters: statusFilterArray,
                    onFilter: (value, record) => {
                        return record.status_id === value + 1;
                    },

                    render: (status_id, record) => {
                        const status = _.find(statusArray, {id: status_id - 1});
                        return (
                            <Tag color={status?.color || "red"} key={status_id}>
                                {record?.statusName || "Bulunamadı"}
                            </Tag>
                        );
                    },
                },
                {
                    title: "Seçenekler",
                    dataIndex: "options",
                    key: "options",
                    render: (text, record) => {
                        return (
                            <Space size="middle">
                                <Dropdown overlay={this._renderMenu(record)}>
                                    <a
                                        className="ant-dropdown-link"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        Seçenekler <DownOutlined/>
                                    </a>
                                </Dropdown>
                            </Space>
                        );
                    },
                },
            ],
            data: [],
            tableLoading: false,
            pagination: {
                current: 1,

                total: 10,
            },
        };
    }

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };
    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({searchText: ""});
    };

    componentDidMount() {
        this.getData();
    }

    getData = async (date = [moment().add(-15, "day"), moment()]) => {
        try {
            this.setState({loading: true});

            const result = await DataService.Sample.SelectAll(
                2,
                0,
                0,
                moment(date[0]).unix(),
                moment(date[1]).unix()
            );
            if (result.status) {
                this.setState({data: result.response.data, loading: false});
            } else {
                this.setState({loading: false});
                Helper.ErrorMessage(result.message);
            }
        } catch (error) {
            console.log("error", error);
            Helper.ErrorMessage(error.toString());
            this.setState({loading: false});
        }
    };
    _showReport = (fileName) => {
        axios.defaults.headers.common["X-API-KEY"] =
            "e3784eb2-b842-4a31-a204-14bc90bc5856";
        axios.defaults.headers.common["Authorization"] =
            Helper.getlocalStorage("Token");
        axios({
            url: `${baseAPIUrl}${Endpoint.Sample.getBlobPdf}/${fileName}`, //your url
            method: "GET",
            responseType: "blob", // important
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${fileName}.pdf`); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                Helper.ErrorMessage(error.toString());
            });
    };


    poasPost = async (sampleId) => {
        try {
            const result = await DataService.Sample.poasRequestFinish(sampleId);
            if (!result.status) {
                Helper.ErrorMessage(result?.message || "Bir hata meydana geldi.");
            } else {
                Helper.SuccessMessage(result?.message);
            }
        } catch (error) {
            Helper.ErrorMessage("Sistemsel bir hata meydana geldi");
        }
    };
    _renderMenu = ({
                       status_id,
                       get_detail,
                       sampleId,
                       fileName,
                       draftReport,
                       buttonEditable,
                       signatureType,
                   }) => (
        <Menu>
            <Menu.Item key="0">
                <NavLink
                    activeClassName="nav-active"
                    exact
                    to={`/sample/sample-report/edit/${sampleId}`}
                >
                    <Button
                        shape="round"
                        type="primary"
                        disabled={status_id == 6 ? false : true}
                    >
                        Rapor Oluştur
                    </Button>
                </NavLink>
            </Menu.Item>
            <Menu.Item key="1">
                <Button
                    shape="round"
                    type="primary"
                    disabled={status_id == 7 ? false : true}
                    onClick={() => (fileName ? this._showReport(fileName) : 0)}
                >
                    Raporu Gör
                </Button>
            </Menu.Item>
            {draftReport == 0 && (
                <Menu.Item key="1">
                    <Button
                        shape="round"
                        type="primary"
                        disabled={status_id == 7 ? false : true}
                        onClick={() =>
                            fileName ? this._showReport(`${fileName}-draft`) : 0
                        }
                    >
                        Taslak Raporu Gör
                    </Button>
                </Menu.Item>
            )}
            {signatureType == 0 && (
                <Menu.Item key="1">
                    <Button
                        shape="round"
                        type="primary"

                        onClick={() =>
                            fileName ? this._showReport(`${fileName}-unsigned`) : 0
                        }
                    >
                        İmzasız Raporu Gör
                    </Button>
                </Menu.Item>
            )}
            <Menu.Item key="3">
                <NavLink
                    activeClassName="nav-active"
                    exact
                    to={`/sample/sample-report/edit/${sampleId}`}
                >
                    <Button
                        shape="round"
                        type="primary"
                        disabled={!(status_id == 7 && Boolean(buttonEditable))}
                    >
                        Raporu Düzenle
                    </Button>
                </NavLink>
            </Menu.Item>
            <Menu.Item key="4">
                <Button
                    shape="round"
                    type="primary"
                    disabled={!(status_id == 7 && Boolean(buttonEditable))}
                    onClick={() => this.sendReportMailMessage({sampleId, fileName})}
                >
                    Mail Gönder
                </Button>
            </Menu.Item>

            {get_detail && (
                <Menu.Item key="1">
                    <Button
                        shape="round"
                        type="primary"
                        disabled={status_id == 7 ? false : true}
                        onClick={() =>
                            this.poasPost(sampleId)
                        }
                    >
                        Poaş'a Gönder
                    </Button>
                </Menu.Item>
            )}
        </Menu>
    );
    sendReportMailMessage = ({sampleId, fileName}) => {
        Helper.alertMessage({
            title: "Mail Gönder",
            message: "Raporu  mail olarak göndermek istiyor musunuz?",

            onDone: () => this.senReportMail({sampleId, fileName}),
        });
    };
    senReportMail = ({sampleId, fileName}) => {
        this.setState({loading: true});
        DataService.Sample.sendReportMail({sampleId, fileName})
            .then((result) => {
                this.setState({loading: false});
                if (result.status) {
                    Helper.SuccessMessage(result.message);
                    this.getData();
                } else {
                    Helper.ErrorMessage(result.message);
                    this.getData();
                }
            })
            .catch((error) => {
                this.setState({loading: false});
                Helper.ErrorMessage(error.toString());
            });
    };
    handleTableChange = (pagination, filters, sorter) => {
        console.log("handleTableChange", {
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            ...filters,
        });
        this.getData({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            ...filters,
        });
    };

    render() {
        return (
            <div>
                <div className="pageHeaderWrapper">
                    <PageHeader title="Numune Raporlama"/>
                </div>

                <Spin spinning={this.state.loading}>
                    <Card bordered={false} className="cardTable">
                        <RangeButton onClick={this.getData} rangeLimit={1}/>
                        <Table
                            columns={this.state.tableColumns}
                            data={this.state.data}
                            pagination={true}
                        />
                    </Card>
                </Spin>
            </div>
        );
    }
}

export default index;
